.ews-loading-container{
  .bp4-overlay-backdrop {
    background: rgba(255,255,255,0.5);
  }
  .ews-loading-wrapper{
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ews-loading-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}