@import "~@blueprintjs/colors/lib/scss/colors.scss";
$colors: #22695a, #dfc170, #b1b1b2, #b23032, #593925;
$colorInt: 1;

.title-container {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;

  h1 {
    font-size: 2.5rem;
    background-color: white;
    padding: 0 1.5rem;
    border-radius: 2rem;
    box-shadow: 0 0 1rem 1rem white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    
    .word-container {
      display: inline;
      margin-left: .25rem;
      margin-right: .25rem;
    }
    
    span {
      @for $i from 1 through 16 {
        @if $colorInt > length($colors) { $colorInt: 1; }
        &:nth-child(#{$i}) {
          color: nth($colors, $colorInt)
        }
        $colorInt: $colorInt + 1;
      }
    }
  }
}