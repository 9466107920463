@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

@font-face {
  font-family: "PWHappyChristmas";
  src: url("../public/fonts/PWHappyChristmas.ttf");
}

.christmas-font {
  font-family: 'PWHappyChristmas';
}