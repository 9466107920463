.selectionName {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: inline-block;
  background-color: white;
  padding: 0 1.5rem;
  border-radius: 2rem;
  box-shadow: 0 0 1rem 1rem white;
  max-width: 100%;
  width: 300px;
  
  .name-elem {
    color: #b1b1b2;
    
    &.selectionCompleted {
      color: #22695a;
    }
  }
}





